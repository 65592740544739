import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const LatestVideos = () => {
  const [videos, setVideos] = useState([]);
  const channelId = 'UCXuqSBlHAE6Xw-yeJA0Tunw';
  const apiKey = 'AIzaSyBfKEr607UkcrLyers00zgKImgBwxN00UA';

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&order=date&part=snippet&type=video&maxResults=10`);
        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className='LatestVideos'>
      <h2 className='LatestVideosTitle'>Last 10 Videos from LinusTechTips</h2>
      <ul className='LatestVideoList'>
        {videos.map((video) => (
          <li key={video.id.videoId}>
            <NavLink to={{pathname: "VideoData"}}
              state={{ videoid: video.id.videoId }}>
              {video.snippet.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestVideos;