import React, { useEffect, useState } from 'react';
import LatestVideos from './LatestVideos';
import axios from 'axios';

function ChannelOverview() {
    const [channelInfo, setChannelInfo] = useState({});
    const [channelStats, setChannelStats] = useState({});
    const channelId = 'UCXuqSBlHAE6Xw-yeJA0Tunw';
    const apiKey = 'AIzaSyBfKEr607UkcrLyers00zgKImgBwxN00UA';

    useEffect(() => {
        const fetchChannelInfo = async () => {
          try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`);
            const item = response.data.items[0];
            setChannelInfo(item.snippet);
            setChannelStats(item.statistics);
          } catch (error) {
            console.error('Error fetching channel info:', error);
          }
        };
    
        fetchChannelInfo();
      }, [channelId]);

    return (
        <div className='ChannelOverviewComponent'>
            <h1 className='ChannelOverviewTitle'>Channel Overview</h1>
            <div className='ChannelOverview'>
                <h2>{channelInfo.title}</h2>
                <img src={channelInfo.thumbnails?.default?.url} alt={`${channelInfo.title} thumbnail`} className='ChannelThumbnail'/>
                <p>{channelInfo.description}</p>

                <h2>Channel Stats</h2>
                <ul className='ChannelOverviewStatsList'>
                    <li>Subscriber Count: {parseInt(channelStats.subscriberCount).toLocaleString()}</li>
                    <li>View Count: {parseInt(channelStats.viewCount).toLocaleString()}</li>
                    <li>Video Count: {parseInt(channelStats.videoCount).toLocaleString()}</li>
                </ul>
            </div>
            <div className='COLatestVideos'>
                <LatestVideos />
            </div>
        </div>
    )
}

export default ChannelOverview;