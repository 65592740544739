import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

const CommentSearch = (props) => {
  const [wordCount, setWordCount] = useState({});
  const videoId = props.videoId;
  const apiKey = 'AIzaSyBfKEr607UkcrLyers00zgKImgBwxN00UA';
  const maxResults = 100;

  Chart.register(CategoryScale);
  Chart.register(LinearScale);
  Chart.register(PointElement);
  Chart.register(LineElement);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`https://www.googleapis.com/youtube/v3/commentThreads?videoId=${videoId}&key=${apiKey}&part=snippet&maxResults=${maxResults}`);
      if (response.data.items) {
        const comments = response.data.items.map(item => item.snippet.topLevelComment.snippet.textDisplay);
        return comments;
      } else {
        console.log('No comments found.');
        return [];
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const countWords = (comments) => {
    if (!comments || comments.length === 0) return;

    const wordFrequency = {};
    const stopWords = ['and', 'or', 'as', 'this', 'that', 'the', 'i', 'it', 'of', 'my', 'a', 'to', 'is', 'for', 'in', 'but', 'on', 'just', 'not', 'with', 'you', 'don\'t', 'don&#39;t', 'be', 'are', 'use', 'an', 'when', 'it\'s', 'it&#39;s', 'at', 'get', 'need', 'no', 'so', 'was', 'if', 'have', 'me', 'your', 'will', 'than', 'only', 'do', 'still', 'can', '<a', 'what', 'would', 'they', 'from', 'there', 'put', 'then', 'see', 'all', 'we', 'them', 'did', 'had'];

    comments.forEach(comment => {
      const words = comment.toLowerCase().split(/\s+/);
      words.forEach(word => {
        if (!stopWords.includes(word)) {
          if (!wordFrequency[word]) {
            wordFrequency[word] = 0;
          }
          wordFrequency[word] += 1;
        }
      });
    });

    const sortedWords = Object.keys(wordFrequency).sort((a, b) => wordFrequency[b] - wordFrequency[a]).slice(0, 10);
    const sortedWordCount = {};
    
    sortedWords.forEach(word => {
      sortedWordCount[word] = wordFrequency[word];
    });

    setWordCount(sortedWordCount);
  };

  useEffect(() => {
    const fetchData = async () => {
      const comments = await fetchComments();
      countWords(comments);
    };

    fetchData();
  }, []);

  const data = {
    labels: Object.keys(wordCount),
    datasets: [
      {
        label: 'Word Frequency',
        data: Object.values(wordCount),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  return (
    <div style={{ width: '400px' }} className='CommentGraph'>
      <h3>Top 10 Words in Comments</h3>
      <Line data={data} />
    </div>
  );
};

export default CommentSearch;