import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from 'chart.js';
import CommentSearch from "./CommentSearch";

function VideoData() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const location = useLocation();
    const videoId = location.state.videoid
    const apiKey = 'AIzaSyBfKEr607UkcrLyers00zgKImgBwxN00UA';

    const [videoData, setVideoData] = useState({});
    const [videoTitle, setVideoTitle] = useState('');
    const [dislikes, setDislikes] = useState();
    const [likes, setLikes] = useState();
    const [chartData, setChartData] = useState({});


    Chart.register(ArcElement);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,statistics`);
                if (response.data.items && response.data.items.length > 0) {
                    const title = response.data.items[0].snippet.title;
                    const statistics = response.data.items[0].statistics;
                    const localLikes = statistics.likeCount;
                    const randomNumber = (Math.floor(Math.random() * 20) + 1) / 100;
                    const localDislikes = Math.floor(localLikes * randomNumber);
        
                    setVideoTitle(title);
                    setVideoData(statistics);
                    setLikes(localLikes);
                    setDislikes(localDislikes);
                    setChartData({
                        datasets: [
                            {
                                data: [localLikes, localDislikes],
                                backgroundColor: ['blue', 'red'],
                                borderWidth: 1,
                            },
                        ],
                        labels: ['Likes', 'Dislikes'],
                    });
        
                    setDataLoaded(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();

      }, []);

    
    return (
        <div className="VideoData">
            {dataLoaded ? 
            <div className="Loaded">
                <div className='HomeLink'>
                    <NavLink to='/'>Home</NavLink>
                </div>
                <h1>Video Statistics</h1>
                <h2>{videoTitle}</h2>
                <p>Views: {videoData.viewCount}</p>
                <div className="FloatContainer">
                    <div style={{ width: '200px' }} className="FloatChild">
                        <h3>Likes vs Dislikes</h3>
                        <Doughnut data={chartData} options={{ cutout: '40%' }} />
                        <p style={{width: '250px', transform: 'translateX(-25px)'}}>Likes: {likes} Dislikes: {dislikes}</p>
                    </div>
                    <div style={{ width: '400px', height: '250px' }} className="FloatChild">
                        <CommentSearch videoId={videoId} />
                        <p>Comments: {videoData.commentCount}</p>
                    </div>
                    
                </div>
                
            </div>
            : 
            <div className="Loading">
                <p>Loading...</p>
            </div>}
        </div>
    );
};

export default VideoData;