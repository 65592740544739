import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router";
import LatestVideos from './LatestVideos';
import ChannelOverview from './ChannelOverview';
import VideoData from './VideoData';


function App() {

  return (
    <div className='container'>
      <div className='secondcontainer'>
      <header>
        <Routes>
          <Route path='/' element={<ChannelOverview />} />
          <Route path="VideoData" element={<VideoData />} />
        </Routes>
      </header>
      <body>
      </body>
      </div>
    </div>
  );
};

export default App;
